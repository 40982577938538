import { HeadFC } from "gatsby";
import React from "react";

import Layout from "../components/Layout";

const Terms = () => {
  return (
    <Layout>
      <div className="container mx-auto px-[10vw] py-32">
        <h1 className="font-serif text-3xl sm:text-[4.5vw] md:text-[10vw] leading-tight md:leading-none mb-20 text-brand">
          Terms
        </h1>
        <p className="text-lg md:text-[2.4vw] leading-tight mb-10">
          These terms and conditions govern your use of our website; by using
          our website, you accept these terms and conditions in full.
        </p>
        <p className="text-base mb-10 font-extralight">
          If you disagree with these terms and conditions or any part of these
          terms and conditions, you must not use our website.
        </p>
        <p className="text-base mb-10 font-extralight">
          You must be at least 18 years of age to use our website. By using our
          website you warrant and represent that you are at least 18 years of
          age.
        </p>
        <h2 className="font-serif font-bold text-xl mb-4 tracking-wide">
          LICENCE TO USE WEBSITE
        </h2>
        <p className="text-base mb-10 font-extralight">
          Unless otherwise stated, we or our licensors own the intellectual
          property rights in the website and material on the website. Subject to
          the licence below, all these intellectual property rights are
          reserved. You may view, download for caching purposes only, and print
          pages or other content from the website for your own personal use,
          subject to the restrictions set out below and elsewhere in these terms
          and conditions.
        </p>
        <p className="text-base mb-10">You must not:</p>
        <ol className="list-decimal pl-[5vw] mb-10 font-extralight">
          <li className="mb-2">
            republish material from this website (including republication on
            another website);
          </li>
          <li className="mb-2">
            sell, rent or sub-license material from the website;
          </li>
          <li className="mb-2">
            show any material from the website in public;
          </li>
          <li className="mb-2">
            reproduce, duplicate, copy or otherwise exploit material on our
            website for a commercial purpose;
          </li>
          <li className="mb-2">
            edit or otherwise modify any material on the website; or
          </li>
          <li className="mb-2">
            redistribute material from this website except for content
            specifically and expressly made available for redistribution.
          </li>
        </ol>
        <h2 className="font-serif font-bold text-xl mb-4 tracking-wide">
          ACCEPTABLE USE
        </h2>
        <p className="text-base mb-10 font-extralight">
          You must not use our website in any way that causes, or may cause,
          damage to the website or impairment of the availability or
          accessibility of the website; or in any way which is unlawful,
          illegal, fraudulent or harmful, or in connection with any unlawful,
          illegal, fraudulent or harmful purpose or activity. You must not use
          our website to copy, store, host, transmit, send, use, publish or
          distribute any material which consists of (or is linked to) any
          spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit
          or other malicious computer software. You must not conduct any
          systematic or automated data collection activities (including without
          limitation scraping, data mining, data extraction and data harvesting)
          on or in relation to our website without our express written consent.
          You must not use our website to transmit or send unsolicited
          commercial communications. You must not use our website for any
          purposes related to marketing without our express written consent.
        </p>
        <h2 className="font-serif font-bold text-xl mb-4 tracking-wide">
          RESTRICTED ACCESS
        </h2>
        <p className="text-base mb-10 font-extralight">
          Access to certain areas of our website is restricted. We reserve the
          right to restrict access to other areas of our website, or indeed our
          whole website, at our discretion. If we provide you with a user ID and
          password to enable you to access restricted areas of our website or
          other content or services, you must ensure that that user ID and
          password is kept confidential. We may disable your user ID and
          password in our sole discretion without notice or explanation.
        </p>
      </div>
    </Layout>
  );
};

export const Head: HeadFC = () => <title>Terms | SEstudio</title>;

export default Terms;
